// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-about-jsx": () => import("./../src/pages/About/About.jsx" /* webpackChunkName: "component---src-pages-about-about-jsx" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-sections-download-jsx": () => import("./../src/pages/About/Sections/Download.jsx" /* webpackChunkName: "component---src-pages-about-sections-download-jsx" */),
  "component---src-pages-about-sections-introduction-jsx": () => import("./../src/pages/About/Sections/Introduction.jsx" /* webpackChunkName: "component---src-pages-about-sections-introduction-jsx" */),
  "component---src-pages-about-sections-team-jsx": () => import("./../src/pages/About/Sections/Team.jsx" /* webpackChunkName: "component---src-pages-about-sections-team-jsx" */),
  "component---src-pages-booking-booking-jsx": () => import("./../src/pages/Booking/Booking.jsx" /* webpackChunkName: "component---src-pages-booking-booking-jsx" */),
  "component---src-pages-booking-js": () => import("./../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-booking-sections-download-jsx": () => import("./../src/pages/Booking/Sections/Download.jsx" /* webpackChunkName: "component---src-pages-booking-sections-download-jsx" */),
  "component---src-pages-booking-sections-introduction-jsx": () => import("./../src/pages/Booking/Sections/Introduction.jsx" /* webpackChunkName: "component---src-pages-booking-sections-introduction-jsx" */),
  "component---src-pages-booking-sections-team-jsx": () => import("./../src/pages/Booking/Sections/Team.jsx" /* webpackChunkName: "component---src-pages-booking-sections-team-jsx" */),
  "component---src-pages-competitions-js": () => import("./../src/pages/competitions.js" /* webpackChunkName: "component---src-pages-competitions-js" */),
  "component---src-pages-components-components-jsx": () => import("./../src/pages/Components/Components.jsx" /* webpackChunkName: "component---src-pages-components-components-jsx" */),
  "component---src-pages-components-sections-section-basics-jsx": () => import("./../src/pages/Components/Sections/SectionBasics.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-basics-jsx" */),
  "component---src-pages-components-sections-section-carousel-jsx": () => import("./../src/pages/Components/Sections/SectionCarousel.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-carousel-jsx" */),
  "component---src-pages-components-sections-section-completed-examples-jsx": () => import("./../src/pages/Components/Sections/SectionCompletedExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-completed-examples-jsx" */),
  "component---src-pages-components-sections-section-download-jsx": () => import("./../src/pages/Components/Sections/SectionDownload.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-download-jsx" */),
  "component---src-pages-components-sections-section-examples-jsx": () => import("./../src/pages/Components/Sections/SectionExamples.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-examples-jsx" */),
  "component---src-pages-components-sections-section-javascript-jsx": () => import("./../src/pages/Components/Sections/SectionJavascript.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-javascript-jsx" */),
  "component---src-pages-components-sections-section-login-jsx": () => import("./../src/pages/Components/Sections/SectionLogin.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-login-jsx" */),
  "component---src-pages-components-sections-section-navbars-jsx": () => import("./../src/pages/Components/Sections/SectionNavbars.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-navbars-jsx" */),
  "component---src-pages-components-sections-section-notifications-jsx": () => import("./../src/pages/Components/Sections/SectionNotifications.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-notifications-jsx" */),
  "component---src-pages-components-sections-section-pills-jsx": () => import("./../src/pages/Components/Sections/SectionPills.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-pills-jsx" */),
  "component---src-pages-components-sections-section-tabs-jsx": () => import("./../src/pages/Components/Sections/SectionTabs.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-tabs-jsx" */),
  "component---src-pages-components-sections-section-typography-jsx": () => import("./../src/pages/Components/Sections/SectionTypography.jsx" /* webpackChunkName: "component---src-pages-components-sections-section-typography-jsx" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-us-contact-us-jsx": () => import("./../src/pages/ContactUs/ContactUs.jsx" /* webpackChunkName: "component---src-pages-contact-us-contact-us-jsx" */),
  "component---src-pages-contact-us-sections-download-jsx": () => import("./../src/pages/ContactUs/Sections/Download.jsx" /* webpackChunkName: "component---src-pages-contact-us-sections-download-jsx" */),
  "component---src-pages-contact-us-sections-introduction-jsx": () => import("./../src/pages/ContactUs/Sections/Introduction.jsx" /* webpackChunkName: "component---src-pages-contact-us-sections-introduction-jsx" */),
  "component---src-pages-contact-us-sections-team-jsx": () => import("./../src/pages/ContactUs/Sections/Team.jsx" /* webpackChunkName: "component---src-pages-contact-us-sections-team-jsx" */),
  "component---src-pages-contact-us-sections-work-section-jsx": () => import("./../src/pages/ContactUs/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-contact-us-sections-work-section-jsx" */),
  "component---src-pages-gallery-gallery-jsx": () => import("./../src/pages/Gallery/Gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-gallery-jsx" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-page-js": () => import("./../src/pages/login-page.js" /* webpackChunkName: "component---src-pages-login-page-js" */),
  "component---src-pages-login-page-login-page-jsx": () => import("./../src/pages/LoginPage/LoginPage.jsx" /* webpackChunkName: "component---src-pages-login-page-login-page-jsx" */),
  "component---src-pages-membership-js": () => import("./../src/pages/membership.js" /* webpackChunkName: "component---src-pages-membership-js" */),
  "component---src-pages-membership-membership-jsx": () => import("./../src/pages/Membership/Membership.jsx" /* webpackChunkName: "component---src-pages-membership-membership-jsx" */),
  "component---src-pages-membership-sections-pre-registration-jsx": () => import("./../src/pages/Membership/Sections/PreRegistration.jsx" /* webpackChunkName: "component---src-pages-membership-sections-pre-registration-jsx" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-news-jsx": () => import("./../src/pages/News/News.jsx" /* webpackChunkName: "component---src-pages-news-news-jsx" */),
  "component---src-pages-news-sections-news-card-jsx": () => import("./../src/pages/News/Sections/NewsCard.jsx" /* webpackChunkName: "component---src-pages-news-sections-news-card-jsx" */),
  "component---src-pages-profile-page-js": () => import("./../src/pages/profile-page.js" /* webpackChunkName: "component---src-pages-profile-page-js" */),
  "component---src-pages-profile-page-profile-page-jsx": () => import("./../src/pages/ProfilePage/ProfilePage.jsx" /* webpackChunkName: "component---src-pages-profile-page-profile-page-jsx" */),
  "component---src-pages-sponsorship-js": () => import("./../src/pages/sponsorship.js" /* webpackChunkName: "component---src-pages-sponsorship-js" */),
  "component---src-pages-sponsorship-sections-product-section-jsx": () => import("./../src/pages/Sponsorship/Sections/ProductSection.jsx" /* webpackChunkName: "component---src-pages-sponsorship-sections-product-section-jsx" */),
  "component---src-pages-sponsorship-sections-team-section-jsx": () => import("./../src/pages/Sponsorship/Sections/TeamSection.jsx" /* webpackChunkName: "component---src-pages-sponsorship-sections-team-section-jsx" */),
  "component---src-pages-sponsorship-sections-work-section-jsx": () => import("./../src/pages/Sponsorship/Sections/WorkSection.jsx" /* webpackChunkName: "component---src-pages-sponsorship-sections-work-section-jsx" */),
  "component---src-pages-sponsorship-sponsorship-jsx": () => import("./../src/pages/Sponsorship/Sponsorship.jsx" /* webpackChunkName: "component---src-pages-sponsorship-sponsorship-jsx" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-success-jsx": () => import("./../src/pages/Success/Success.jsx" /* webpackChunkName: "component---src-pages-success-success-jsx" */),
  "component---src-pages-tournament-js": () => import("./../src/pages/tournament.js" /* webpackChunkName: "component---src-pages-tournament-js" */),
  "component---src-pages-tournament-sections-pre-registration-jsx": () => import("./../src/pages/Tournament/Sections/PreRegistration.jsx" /* webpackChunkName: "component---src-pages-tournament-sections-pre-registration-jsx" */),
  "component---src-pages-tournament-tournament-jsx": () => import("./../src/pages/Tournament/Tournament.jsx" /* webpackChunkName: "component---src-pages-tournament-tournament-jsx" */),
  "component---src-pages-training-js": () => import("./../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-training-training-jsx": () => import("./../src/pages/Training/Training.jsx" /* webpackChunkName: "component---src-pages-training-training-jsx" */),
  "component---src-pages-welcome-instagram-jsx": () => import("./../src/pages/Welcome/Instagram.jsx" /* webpackChunkName: "component---src-pages-welcome-instagram-jsx" */),
  "component---src-pages-welcome-js": () => import("./../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-pages-welcome-welcome-jsx": () => import("./../src/pages/Welcome/Welcome.jsx" /* webpackChunkName: "component---src-pages-welcome-welcome-jsx" */),
  "component---src-templates-news-article-jsx": () => import("./../src/templates/newsArticle.jsx" /* webpackChunkName: "component---src-templates-news-article-jsx" */)
}

